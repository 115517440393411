/**
 * These are the polyfills that are currently needed for older browsers
 */

import "core-js";
import "custom-event-polyfill";

/**
 * This is an async / await polyfill which is needed in all browsers
 */

import regeneratorRuntime from "regenerator-runtime";
window.regeneratorRuntime = regeneratorRuntime;

/**
 * window.location.origin polyfill
 */
if (typeof window.location.origin === "undefined") {
	window.location.origin = `${window.location.protocol}//${window.location.hostname}${
		window.location.port ? `:${window.location.port}` : ""
	}`;
}
